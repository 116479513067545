import { useEffect, useRef } from "react";

export const useVideoAutoPlay = (threshold: number = 0.6) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) return;

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          videoElement.play();
        } else {
          videoElement.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold,
    });

    observer.observe(videoElement);

    return () => {
      observer.disconnect();
    };
  }, [threshold]);

  return videoRef;
};
